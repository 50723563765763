import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell de color beix crema passant a marró daurat quan és adult. Aquest mesura de 4 a 6 cm de diàmetre primer hemisfèric i després s’obre, amb un mamelló al centre. Les làmines de color blanc i després grisenc tenen l’aresta un poc dentada i més blanca. A més, són escotades. El peu més curt que el diàmetre del capell, dret i lleugerament bulbós cap a la base. És de color blanc i presenta cortina de jove. Les espores són marronenques en massa, el·líptiques, i amb un porus a un dels extrems, de 8-11,5 x 6-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      